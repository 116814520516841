<template>
    <av-app-left-panel v-if="currentOrg.orgName && currentOrg.orgName.length > 0" :selected-tab-id="selectedTabId"></av-app-left-panel>
    <router-view/>
</template>


<script>

import { createNamespacedHelpers } from 'vuex';
import store from '@/store';
import router from '@/router';
import AppLeftPanel from '@/components/av-app-left-panel.vue';

const usersState = createNamespacedHelpers('users');
const orgState = createNamespacedHelpers('organizations');

export default {

	components: {
		'av-app-left-panel' : AppLeftPanel
	},

	data: function () {
		return {
			selectedTabId : ''
		};
	},

	mounted(){

	},

	created() {
		this.$watch(
			() => this.$route,
			(to /*, previousParams */) => {
				this.selectedTabId = to.matched[1].name;
			}
		);

		this.selectedTabId = this.$route.matched[1].name;

	},


	async beforeRouteEnter(to){
		let currentUser;
			store.dispatch('organizations/getCurrentOrg').then((response) => {
				if(to.meta.auth && response.orgName === null){
					router.push('/landingPage');
				}
			});
			await store.dispatch("users/getCurrentUser").then((response) => {
				currentUser = response;
			});
			await store.dispatch('users/getUserPreference', currentUser.userId);
			await store.dispatch("taskstatuses/getAll");
			store.dispatch("taskpriorities/getAll");
	},

	computed: {
		...usersState.mapState([
			'currentAuthUser',
			'preferenceForUser'
		]),
		...orgState.mapState([
			'currentOrg'
		])
    },

	methods: {
	

    }
};
</script>