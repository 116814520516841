<template>
  <div class="left-bar flex--col">
    <div class="hdr">
        <el-dropdown class="user-name-dd" trigger="click" hide-on-click>
            <span class="user-dropdown">
                <div class="ttle flex--row flex--acjc font-bold">
                  <template v-if="currentAuthUser.profilePicURL === null">
                    <span :style="{'background-color': currentAuthUser.profilePicColor, 'color' : checkColorluminance(currentAuthUser.profilePicColor)}" class ="cp icon profile-pic flex--row flex--acjc cp">{{ currentAuthUser.firstName.charAt(0).toUpperCase() }}</span>
                  </template>
                  <img v-else class="profile-pic" :src="imgsrc()" />
                  <span class="name clip-txt cp" >{{ currentAuthUser.firstName }}</span>
                  <img class="editIcon" src="../icons/Pencil.png"/>
                </div>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <div class="profile flex--col flex--acjc">
                  <div class="profile-menu flex--row flex--acjc">
                  <template v-if="currentAuthUser.profilePicURL === null">
                    <span :style="{'background-color': currentAuthUser.profilePicColor, 'color' : checkColorluminance(currentAuthUser.profilePicColor) }" class = "cp txt16 pic-img flex--row flex--acjc cp">{{ currentAuthUser.firstName.charAt(0).toUpperCase() }}</span>
                  </template>
                  <img v-else class="profile-pic-user pic-img" :src="imgsrc()" />
                    <div>
                      <span>Available</span>
                    </div>
                  </div>
                  <div class="profile-menu-list flex--col">
                    <span class="cp" @click="openProfileForm = true " >My Preferences</span>
                    <template v-if="currentAuthUser.userId === createdOrgUserId">
                      <span @click="adminConsole = true" class="cp">Admin console</span>
                    </template>
                    <span class="cp">
                      <a @click="getUrl()" :href="logoutURL">
                        {{ $t("av.global.logout") }}
                      </a>
                    </span>
                  </div>
                </div>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
          <el-dropdown trigger="click" class="user-org-dd">
            <span class="user-dropdown">
              <div class="ttle flex--row flex--acjc font-bold">
                <span class="clip-txt cp" >{{ currentOrg.orgName }}</span>
                    <div class="flex--col flex--acjc arrws">
                        <av-icon class="cp arrw-icons medium rot-180" name="arrow-style2-down"></av-icon>
                        <av-icon class="cp arrw-icons medium" name="arrow-style2-down"></av-icon>
                    </div>
              </div>
            </span>
            <template #dropdown>
              <el-dropdown-menu >
                <el-dropdown-item @click="switchOrg(org)" v-for="org in allOrganization" :key="org.orgId">
                  <span v-if="currentOrg.orgName !== org.orgName">{{ org.orgName }}</span>
                </el-dropdown-item>
                <el-dropdown-item @click="workSpaceDialog = true">
                  <span>+ Create workspace</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
        </el-dropdown>
      <ul class="flex--col flex--one-oflw options">
        <!-- <li class="flex--row flex--ac cp" :class="[selectedTabId === 'search' ? 'sel' : '']">
          <router-link to="/search" class="flex--row flex--ac">
            <av-icon class="regular" name="search"></av-icon>
            <span> {{$t('av.global.search')}} </span>
          </router-link>
        </li> -->
         <!-- <li class="flex--row flex--ac cp">
            <av-icon class="regular" name="bell"></av-icon>
            <span> {{$t('av.global.notifications')}} </span>
        </li> -->
      </ul>
      <span class="dB bottom-line"></span>
    </div>
    <ul id="nav-bar" class="flex--col flex--one-oflw">
      <li class="flex--row flex--ac cp" v-for="tab in tabs" :key="tab.id" :class="[selectedTabId === tab.id ? 'sel' : '']">
        <router-link :to="'/'+tab.id" class="flex--row flex--ac">
          <av-icon class="regular" :name="tab.icon"></av-icon>
          <span> {{ $t(tab.label) }} </span>
        </router-link>
      </li>
    </ul>
    <user-detail v-if="openProfileForm" @onClose="openProfileForm = false"></user-detail>

        <el-dialog v-model="adminConsole" width="50%" :show-close="false" custom-class="adm-console" :before-close="closingAdminConsole">
          <p contenteditable ref="OrgName" spellcheck="false" class="org-name" @blur="updateOrgName">{{ currentOrg.orgName }}</p>
              <el-tabs v-model="AdmConsoleActiveName" class="demo-tabs">

                <el-tab-pane label="Invite members" name="Invite-members">
                  <div>
                    <span>Invite members</span>
                    <div class="mail-box flex--col">
                      <form @submit.prevent >
                        <div class="inviteDiv" @click="$refs.inviteMember.focus()">
                            <el-tag v-for="tag in inviteTag" :key="tag" effect="light" :closable="true" @close="deleteInviteTag(tag)" round>
                              {{ tag }}
                            </el-tag>
                            <input :size="inputSize" @input="increaseInputSize" class="inviteInput" type="email" ref="inviteMember" v-model="userMailVal" required multiple
                              pattern="[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-zA-Z]{2,4}"  @keydown="submitInviteForm"/>
                        </div>
                      </form>
                      <el-button class="submitBtn" type="primary" @click="inviteMembers">invite</el-button>
                    </div>
                      <span class="inv-ttl" v-if="showInvitedUserList">Invited Members</span>
                      <div class="inact-user flex--row flex--sbw" v-for="orgMember in currentOrg.members" :key="orgMember.user.userId">
                        <template  v-if="orgMember.user.firstName === null">
                          <span>{{ orgMember.user.email }}</span>
                          <span class="inv-opt">
                            <span class="cp">resend</span>
                            <span>or</span>
                            <span @click="deleteMember(orgMember)" class="cp">delete</span>
                          </span>
                        </template>
                      </div>
                  </div>
                </el-tab-pane>
                <el-tab-pane class="mng-mbr" label="Manage members" name="Manage-members">
                  <div class="mbr-row flex--row" v-for="orgMember in currentOrg.members" :key="orgMember.user.userId">
                    <template  v-if="orgMember.user.firstName !== null">
                      <template v-if="orgMember.user.profilePicURL === null">
                        <span :style="{ 'background-color': orgMember.user.profilePicColor, 'color': checkColorluminance(orgMember.user.profilePicColor) }"  class = "user-pic flex--row flex--acjc cp">{{ orgMember.user.firstName.charAt(0).toUpperCase() }}</span>
                      </template>
                      <template v-else>
                        <img v-if="orgMember.user.provider !== 'local' && orgMember.user.profilePicName === null " class="user-pic cp" :src='orgMember.user.profilePicURL'/>
                        <img v-else class="user-pic" :src='origin + "/profilePic/" +  orgMember.user.profilePicURL'/>
                      </template>

                      <div class="mbr-name flex--col">
                        <span>{{ orgMember.user.firstName }}</span>
                        <span>{{ orgMember.user.email }} </span>
                      </div>
                      <el-dropdown class="role-dd" trigger="click">
                        <span>{{ orgMember.roles[0].name }}</span>
                            <template #dropdown>
                              <el-dropdown-menu>
                                <el-dropdown-item v-for="role in roles.slice(0,3)" :key="role.roles_id" @click='changeRoleForMember(role.roles_id, orgMember.user.userId)'>{{ role.name }}</el-dropdown-item>
                              </el-dropdown-menu>
                            </template>
                      </el-dropdown>
                        <span class="rmv-btn flex--row flex--ac cp" @click="deleteMember(orgMember)" v-if="createdOrgUserId !== orgMember.user.userId">Remove</span>
                    </template>
                  </div>
                </el-tab-pane>
              </el-tabs>
        </el-dialog>

        <el-dialog v-model="workSpaceDialog" title="Create Your Workspace" width="30%" :show-close="false" @close="workSpaceName ? '' : ''" :close-on-press-escape ="false">
          
          <span>Set your Workspace name :</span>
            <el-input type="text" v-model="workSpaceName" @keypress.enter="createOrg" placeholder="enter workspace name"/>

          <template #footer>
          <span class="dialog-footer">
            <el-button type="primary" @click="createOrg">Create Workspace</el-button>
          </span>
          </template>

        </el-dialog>

  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import avIcon from '@/components/av-icon.vue';
import userdetail from '@/components/av-userdetails.vue';
import { ElMessage } from 'element-plus';

const usersState = createNamespacedHelpers('users');
const orgState = createNamespacedHelpers('organizations');

export default {
	components : {
		'av-icon' : avIcon,
    'user-detail' :userdetail
	},

	props : {
		selectedTabId : String
	},

  data: function () {
		return {
      openProfileForm: false,
			tabs: [
				{ id: 'home', label: 'av.global.home', icon : 'home' },
				{ id: 'tasks', label: 'av.global.tasks', icon : 'done-outline' }
				// { id: 'activities', label: 'av.global.projects', icon : 'folder'}
			],
      logoutURL : '',
      adminConsole : false,
      AdmConsoleActiveName : 'Invite-members',
      OrgName : '',
      userMailVal : '',
      createdOrgUserId : '',
      workSpaceDialog : false,
      workSpaceName : '',
      origin : window.location.origin,
      inputSize : '1',
      inviteTag : [],
      eventKey : {
          key : 'Enter'
      }
		};
	},

  mounted(){

    $$avQS('.el-dialog.adm-console > .el-dialog__header').remove();
    // this.randColor = [Math.floor(Math.random() * this.colorArr.length)];
  },

  created(){
    
    this.$store.dispatch('organizations/getAllOrg');
    this.$store.dispatch('organizations/getRoles');
		this.createdOrgUserId = this.currentOrg.createdUser.userId;
    this.imgsrc();

  },
  computed: {
		...usersState.mapState([
			'currentAuthUser'
		]),

    ...orgState.mapState([
			'allOrganization',
			'currentOrg',
      'roles'
		]),
    
    showInvitedUserList(){
        return this.currentOrg.members.some((member) => {
            if(member.user.firstName === null){
                return true;
            }
            return false;
        });
    }
	},

  methods:{

    checkColorluminance(color){
        const r = parseInt(color.slice(1, 3), 16);
        const g = parseInt(color.slice(3, 5), 16);
        const b = parseInt(color.slice(5, 7), 16);

        const relativeLuminance = ((0.2126 * r) + (0.7152 * g) + (0.0722 * b)) / 255;

        if (relativeLuminance > 0.5) {
            return '#000000';
        }
            return '#FFFFFF';

    },
    
    async switchOrg(org){
      await this.$store.dispatch('organizations/getOneOrg', org.orgId);
      this.$router.go(this.$router.currentRoute);
    },

    getUrl(){

      this.logoutURL = window.location.origin + "/logout" ;
    },

    imgsrc(){
      
			let url = this.currentAuthUser.profilePicURL;
        if(url !== undefined){
          if(this.currentAuthUser.provider !== 'local' && this.currentAuthUser.profilePicName === null ){
            this.picUrl = `${url}`;
            return this.picUrl;
          }
          this.picUrl = `${window.location.origin}/profilePic/${url}`;
          return this.picUrl;
        }
		},

    closingAdminConsole(){
      this.adminConsole = false;
      this.OrgName = '';
      this.userMailVal = '';
      this.inviteTag = [];
    },

    updateOrgName(){

      let orgName = this.$refs.OrgName.innerText.trim();
      if(orgName !== ''){

        let updateOrg = {};
        updateOrg.orgId = this.currentOrg.orgId;
        updateOrg.orgName = orgName;
        this.$store.dispatch('organizations/updateOrg', updateOrg);

      }
    },

    increaseInputSize(){
      if(this.$refs.inviteMember.value.length >= 1){
        this.inputSize = this.$refs.inviteMember.value.length + 1;
        return ;
      }
      this.inputSize = '1';
    },

    deleteInviteTag(tag){
      this.inviteTag.splice(this.inviteTag.indexOf(tag), 1);
    },

    removeTag(){
        if(this.inviteTag.length && this.userMailVal.trim() === ''){
            this.inviteTag.pop();
        }
    },

    submitInviteForm(e){
      
        if(e.key === 'Backspace'){
            this.removeTag();
        }

        else if(e.key === 'Enter'){

              if(this.$refs.inviteMember.validity.valid){

                if(this.inviteTag.length === 0){
                  this.inviteTag.push(this.userMailVal.trim());
                  this.userMailVal = '';
                  this.inputSize = '1';
                }

                else{

                  this.inviteTag.forEach((tag) => {
                    if(tag !== this.userMailVal.trim() &&  this.userMailVal !== ''){
                      this.inviteTag.push(this.userMailVal.trim());
                      this.userMailVal = '';
                      this.inputSize = '1';
                    }

                    else if(this.userMailVal !== ''){
                      ElMessage({
                        message: `The mail id already exists`,
                        type: 'warning'
                      });
                    }

                  });
                }
              }
          
        }

    },

    async inviteMembers(){
      let regExp = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-zA-Z]{2,4}/;

        if(regExp.test(this.userMailVal) || this.inviteTag.length){

            if(!regExp.test(this.userMailVal) && this.userMailVal !== ''){
               ElMessage({
                message: `Invalid mailId`,
                type: 'error'
                });
                return ;
            }

            if(this.userMailVal !== ''){
              this.submitInviteForm(this.eventKey);
            }

            if(this.userMailVal === ''){
                let user = {};
                user.orgId = this.currentOrg.orgId;
                user.members = this.inviteTag;
                this.inviteTag = [];

                ElMessage({
                  message: `Invite sent successfully`,
                  type: 'success'
                  });

                await this.$store.dispatch('organizations/inviteUsers', user);
                return ;
            }
          }

          else if(!/[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-zA-Z]{2,4}/.test(this.userMailVal)){
            ElMessage({
              message: `Invalid mailId`,
              type: 'error'
              });
          }

    },

    changeRoleForMember(roleId, userId){
      this.currentOrg.members.filter((memb) => {
        if(memb.user.userId === userId){
          // let role = this.roles.find(e => e.roles_id === roleId);
          memb.roles[0] = this.roles.find(e => e.roles_id === roleId);
          this.$store.dispatch('organizations/updateMemberRole', memb);
        }
      });
    },

    async deleteMember(orgMemb){

      this.currentOrg.members.splice(this.currentOrg.members.indexOf(orgMemb), 1);
      await this.$store.dispatch('organizations/deleteOrgMember', orgMemb.orgUserId);
    },

    async createOrg(){

			if(this.workSpaceName && this.workSpaceName.length > 0 ){
        let newOrg = {};
				newOrg.orgName = this.workSpaceName.trim();
        this.workSpaceName = '';
        this.workSpaceDialog = false;
				await this.$store.dispatch('organizations/createOrg', newOrg);
        this.$router.go(this.$router.currentRoute);
			}
		}

  }
};
</script>

<style>

.inviteDiv{
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 3px;
    /* overflow: auto;
    height: 40px; */
    cursor :text
}

.inviteDiv .el-tag{
  height: 25px;
  line-height: 25px;
}

.inviteInput{
    color: #606266;
    font-size: 14px;
    width: auto;
    max-width: 90%;
    margin-left: 5px;
}

.org-input{
  margin-bottom: 1rem;
}
.orgProfile{
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 1rem;
}
.left-bar {
  width: 15vw;
  background: linear-gradient(180deg, var(--color-1) 0%, var(--color-2) 100%);
}
.left-bar > .hdr {
  padding-bottom: 1.5rem;
}
.left-bar > .hdr .user-name-dd{
  width: 100%;
}
.left-bar > .hdr > .bottom-line{
  height: 0.0625rem;
  background: var(--color-4);
  margin: 1.5rem 1.625rem 0;
}
.left-bar > .hdr .user-org-dd{
  width: 100%;
}
.left-bar > .hdr .user-dropdown > .ttle{
  padding: 0 1.625rem 0 1.5rem;
}
.left-bar > .hdr .user-dropdown > .ttle {
  height: 2.8rem;
  color: var(--color-white);
}
.user-dropdown .editIcon{
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
    cursor: pointer;
}
.left-bar > .hdr .user-dropdown > .ttle > .profile-pic{
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
  object-fit: cover;
}
.left-bar > .hdr .user-dropdown > .ttle > .name{
  padding-left : 0.75rem;
  width: 65%;
}
.left-bar > .hdr .user-dropdown > .ttle > .arrws{
  padding-left: 0.5rem;
}
.left-bar > .hdr .user-dropdown > .ttle .arrw-icons{
    color: var(--color-4);
}
.left-bar > .hdr .user-dropdown > .ttle .arrw-icons.rot-180{
    margin-bottom: -0.5rem;
    transform: rotate(180deg);
}
.left-bar > .hdr > .options > li,
.left-bar > #nav-bar > li {
  height: 2rem;
  padding: 0 1.625rem;
}

.left-bar > .hdr > .options > li,
.left-bar > #nav-bar > li {
  color: var(--color-4);
}

.left-bar > .hdr > .options > li:hover,
.left-bar > #nav-bar > li.sel,
.left-bar > .hdr > .options > li.sel,
.left-bar > #nav-bar > li:hover {
  background-color: var(--color-3);
  color: var(--color-white);
}
.left-bar > #nav-bar > li> a{
  display: flex;
  text-decoration: none;
}
.left-bar > .hdr > .options > li span,
.left-bar > #nav-bar > li span{
  padding-left: 0.75rem;
}

.profile{
  padding: 0 17px;
}
.profile-menu{
  padding: 15px 40px 15px 20px;
  border-bottom: 1px solid #E0E0E0;
}
.profile-pic-user{
    /* border-radius: 50%; */
    object-fit: cover;
    color: var(--color-8);
}
.profile-menu .pic-img{
    border-radius: 50%;
    object-fit: cover;
    color: #fff;
    height: 50px;
    width: 50px;
}
.profile-menu > div{
  padding-left: 1rem;
}
.profile-menu > div > span{
  font-weight: 400;
  font-size: 16px;
  padding-right: 0.5rem;
  line-height: 18.75px;
  color: #000000;
}
.profile-menu > div > img{
  width: 16px;
  height: 16px;
}
.profile-menu-list{
  padding: 15px 40px 15px 20px;
}
.profile-menu-list > span{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  margin: 7px 0;
}
.mng-mbr .mbr-row{
  margin-bottom: 0.3rem;
}
.mng-mbr .mbr-row > .mbr-name{
  width: 50%;
}
.mng-mbr .mbr-row .el-dropdown.role-dd{
  width: 27%;
  align-items: center;
  display: flex;
}
.mng-mbr .mbr-row .el-dropdown.role-dd .el-dropdown-selfdefine{
  cursor: pointer;
}

.mbr-name > span:nth-child(2n){
  font-size: 13px;
}
.mng-mbr .mbr-row > .rmv-btn{
  color: #F55C5C;
}

.mail-box{
  margin-bottom: 1rem;
}
.mail-box .submitBtn, .submitBtn{
  padding: 6px 25px;
  margin-top: 15px;
  width: 7rem;
  margin-left: auto;
}

.left-bar .el-dialog.adm-console > .el-dialog__body{
  padding: calc(var(--el-dialog-padding-primary) + 0px) var(--el-dialog-padding-primary);

}
.adm-console .org-name{
    border: 1px solid transparent;
    padding: 1px 3px;
    border-radius: 3px;
    margin-bottom: 0px;
    cursor: text;
    font-size: 19px;
    font-weight: 300;
    color: #262222;
}

.adm-console .org-name:hover{
    border: 1px solid #afabac;
}
.adm-console .inv-ttl{
  font-size: 18px;
}

.inact-user{
  margin: 3px;
}
.inact-user .inv-opt span{
  font-size: 14px;
}
.inact-user .inv-opt span:nth-child(2n){
  margin: 0 0.5rem;
}
.adm-console .inact-user > span:first-child{
  font-size: 1rem;
}
.demo-tabs .mailInp{
  /* border: 1px solid #79bbff;
  border-radius: 4px */
}
.demo-tabs .mailInp > .el-input {
  /* width: 30%; */
}
.user-pic{
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 13px;
  color: #fff;
}
/* :deep(.demo-tabs .mailInp > .el-input input.el-input__inner){
  border: none;
} */
.mail-val{
  color: red;
}


</style>
