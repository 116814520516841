<template>
  <div class="landing-page-main-cont flex--col flex--one flex--ac">
    <div class="landing-page">
        <div class="lnd-pg-header flex--row flex--ac">
            <span>{{ greet }}, {{ currentAuthUser.firstName }}</span>
            <span><img class="Logo" src="../icons/astravue-logo.svg" /></span>
        </div>
        <el-carousel @change="slideChanged" ref="slideCarousel" :initial-index="setSlideView" trigger="click" height="250px" arrow="never" :autoplay="false" indicator-position="none">
            <el-carousel-item name="0">
                <div class="wrkSpc-view flex--col flex--acjc">
                    <span>Create Workspace</span>
                    <form @submit.prevent >
                        <input class="wrkSpaInp" @input="createWorkSpaceName" @keypress.enter="enterworkSpace" required minlength="3" type="text" v-model="workSpaceName" placeholder="Enter workspace name"/>
                    </form>
                </div>
            </el-carousel-item >
            <el-carousel-item name="1" >
                <div class="wrkSpc-view flex--col flex--acjc">
                    <span>Invite members</span>
                    <form @submit.prevent >
                        <div class="inviteDiv" @click="$refs.inviteMember.focus()">
                            <el-tag v-for="tag in inviteTag" :key="tag" effect="light" :closable="true" @close="deleteInviteTag(tag)" round>
                              {{ tag }}
                            </el-tag>
                            <input :size="inpSize" class="inviteInput" type="email" ref="inviteMember" @input="checkMailValidation" v-model="inviteUserMailId" required multiple
                            pattern="[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-zA-Z]{2,4}"  @keydown="submitInviteForm"/>
                        </div>
                    </form>
                </div>
            </el-carousel-item>
            <el-carousel-item name="2" >
                <div class="wrkSpc-view flex--col flex--acjc">
                    <span>Select layout</span>
                    <div class="flex--row">
                        <div @click="choosetaskview('list')" :class="{ selected: selectedLayout === 'list' }"  class="cp lyt-category flex--row flex--ac">
                            <av-icon class="layout-icon" name="list-view"></av-icon>
                            <span>List View</span>
                        </div>
                        <div @click="choosetaskview('kanban')" :class="{ selected: selectedLayout === 'kanban' }"  class="cp lyt-category flex--row flex--ac">
                            <av-icon class="layout-icon" name="board-view"></av-icon>
                            <span>Board View</span>
                        </div>
                    </div>
                </div>
            </el-carousel-item>
        </el-carousel>
        <div class="slide-Box flex--row">
            <el-button class="prev_btn" v-if="setSlideView !== 0" @click="prevSlide">Prev</el-button>
            <el-button :disabled="disBtn"  class="next_btn" v-if="setSlideView === 0" @click="this.$refs.slideCarousel.next()">Next</el-button>
            <el-button class="next_btn" :disabled="disMailBtn" v-if="setSlideView === 1" @click="nextSlide">Continue</el-button>
            <el-button class="next_btn" v-if="setSlideView === 2" @click="LaunchTaskPage">Go to task</el-button>
        </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import avIcon from '@/components/av-icon.vue';

const orgState = createNamespacedHelpers('organizations');
const usersState = createNamespacedHelpers('users');

export default {

    components : {
        'av-icon' : avIcon
    },

    data(){
        return {
            greet : '',
            setSlideView: 0,
            workSpaceName : '',
            inviteUserMailId : '',
            disBtn : true,
            disMailBtn : false,
            selectedLayout : 'list',
            setNewOrg : {},
            inviteUserObj : {},
            userpreArr : [],
            inviteTag : [],
            inpSize : '1',
            eventKey : {
                key : 'Enter'
            }
        };
    },

    created(){

        let hrs = new Date().getHours();
		if (hrs < 12) this.greet = 'Good Morning';
		else if (hrs >= 12 && hrs <= 16) this.greet = 'Good Afternoon';
		else if (hrs >= 16 && hrs <= 24) this.greet = 'Good Evening';
    },

    computed: {
		...usersState.mapState([
			'currentAuthUser',
            'preferenceForUser'
		]),
        ...orgState.mapState([
			'currentOrg'
		])
	},

    methods : {
        prevSlide(){
            this.$refs.slideCarousel.prev();
        },

        nextSlide(){
            this.$refs.slideCarousel.next();
            this.submitInviteForm(this.eventKey);
        },

        slideChanged(newIndex){
            this.setSlideView = newIndex;
        },

        createWorkSpaceName(){

            if(this.workSpaceName && this.workSpaceName.length >= 3) {
                this.setNewOrg.orgId = this.currentOrg.orgId;
                this.setNewOrg.orgName = this.workSpaceName.trim();
                this.disBtn = false;
            }
            
            else {
                this.disBtn = true;
            }
        },

        enterworkSpace(){

            if(this.workSpaceName && this.workSpaceName.length >= 3) {
                this.$refs.slideCarousel.next();
            }

        },

        checkMailValidation(){

            this.increaseInpSize();
            let regexp =  /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-zA-Z]{2,4}/;
            if(regexp.test(this.inviteUserMailId) || this.inviteUserMailId === ''){
                this.disMailBtn = false;
                return;
            }
                this.disMailBtn = true;

        },

        increaseInpSize(){

            if(this.$refs.inviteMember.value.length >= 1){
                this.inpSize = this.$refs.inviteMember.value.length + 1;
                return ;
            }
            this.inpSize = '1';

        },

        removeTag(){
            if(this.inviteTag.length && this.inviteUserMailId.trim() === ''){
                this.inviteTag.pop();
            }
        },

        submitInviteForm(e){
            if(e.key === 'Backspace'){
                this.removeTag();
            }
            else if(e.key === 'Enter'){
                
                if(this.$refs.inviteMember.validity.valid){
                    if(this.inviteTag.length === 0){
                        this.inviteTag.push(this.inviteUserMailId.trim());
                    }

                    else{
                        this.inviteTag.forEach((tag) => {
                            if(tag !== this.inviteUserMailId.trim() && this.inviteUserMailId !== ''){
                                this.inviteTag.push(this.inviteUserMailId.trim());
                                this.inviteUserMailId = '';
                                this.inpSize = '1';
                            }
                        });
                    }

                    this.inviteUserMailId = '';
                    this.inpSize = '1';
                    this.inviteUserObj.orgId = this.currentOrg.orgId;
                    this.inviteUserObj.members = this.inviteTag;
                }

            }
        },

        deleteInviteTag(tag){
            this.inviteTag.splice(this.inviteTag.indexOf(tag), 1);
        },

        choosetaskview(value){
            this.selectedLayout = value;
            this.preferenceForUser.userPreferences.taskPreference.mode = value;
			this.userpreArr.userId = this.currentAuthUser.userId;
			this.userpreArr.preference = this.preferenceForUser;
        },

        async LaunchTaskPage(){

            if(Object.entries(this.userpreArr).length){
                this.$store.dispatch('users/updateUserPreference', this.userpreArr);
                this.userpreArr = [];
            }

            await this.$store.dispatch('organizations/updateOrg', this.setNewOrg);
            this.setNewOrg = {};

            if(Object.entries(this.inviteUserObj).length){
                setTimeout(() => {
                    this.$store.dispatch('organizations/inviteUsers', this.inviteUserObj);
                    this.inviteUserObj = {};
                }, 2000);
            }

            this.$router.push('/tasks');
        }


    }

};
</script>

<style>

.landing-page-main-cont{
    padding: 70px 10px;
    background: white;
    width: 100%;
    height: 100%;
    overflow: auto;


}

.landing-page{
    width: 50%;
    background-color: white;
    border-radius: 1rem 1rem 1rem 1rem;
    box-shadow: 0px 1px 10px 0px rgb(122 122 122 / 35%);
    padding: 30px;
}

.lnd-pg-header{
    justify-content: start;
}

.lnd-pg-header span:nth-child(2n + 1){
    font-size: 18px;
    color: #444444;
    font-weight: 300;
}

.lnd-pg-header span:nth-child(2n) {
    margin-left: auto;
}

.Logo{
    width: 4rem;
    height: 4rem;
}

.el-carousel{
    width: 85%;
    margin: 0 auto;
    
}
.el-carousel__item h3 {
  color: #475669;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
  text-align: center;
}

.el-carousel__item {
  background-color: #fff;
}

/* .el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
} */

.slide-Box{
    justify-content: start;
}

.slide-Box .el-button{
    background: #940707;
    color: #fff;
    outline: none;
}

.slide-Box .el-button:hover{
    color: #fff;
    border-color: #940707;
    background: #940707;
}

.slide-Box .el-button:disabled{
    color: var(--el-button-disabled-font-color);
    background-image: none;
    background-color: var(--el-button-disabled-background-color);
    border-color: var(--el-button-disabled-border-color);

}
.next_btn{
    margin-left: auto;
    /* background: #940707;
    color: #fff; */
}

.slide-Box .el-button+.el-button, .el-checkbox.is-bordered+.el-checkbox.is-bordered{
    margin-left: auto;
}

.wrkSpc-view{
    height: 100%;
    padding: 0px 5rem;
}

.wrkSpc-view form {
    width: 100%;
}

.wrkSpc-view form .inviteDiv{
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 3px;
    /* overflow: auto;
    height: 40px; */
    cursor :text
}
.wrkSpc-view form .inviteDiv .inviteInput{
    color: #606266;
    font-size: 14px;
    width: auto;
    max-width: 90%;
    margin-left: 5px;
    border: none;
}
.wrkSpc-view form .inviteDiv .el-tag{
  height: 25px;
  line-height: 25px;
}


.wrkSpc-view > span{
    font-family: Poppins-Regular, 'Segoe UI', Tahome, Geneva, Verdana, sans-serif;
    font-size: 2rem;
    color: #444444;
    font-weight: 300;
    margin-bottom: 1rem;
}
.wrkSpc-view .wrkSpaInp{
    width: 100%;
    border: 0.05rem solid #A3A3A3;
    border-radius: 0.3rem;
    padding: 0.5rem 11px;
    font-family: Roboto-Regular, 'Segoe UI', Tahome, Geneva, Verdana, sans-serif;
    font-size: small;
}
.wrkSpc-view svg.av-icon{
    height: 2.5rem;
    width: 2.5rem;
    margin-right: 10px;
    color: #39c1ebdb;

}
.wrkSpc-view .lyt-category:nth-child(2n) svg.av-icon{
    color: #b36bd4;
}

.wrkSpc-view .lyt-category{
    width: 12rem;
    border: 1.5px solid #edeae9;
    border-radius: 0.5rem;
    box-sizing: border-box;
    padding: 10px 15px;
    margin-right: 15px;
}

.wrkSpc-view .lyt-category.selected{
    border : 1.5px solid #afabac;
    box-shadow : 0 0 1.5px 0;
}
.wrkSpc-view .lyt-category span{
    font-size: 16px;
}


</style>