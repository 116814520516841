<template>
	<div>
		<button @click=getData()>Get Data</button>
		<h1>{{ this.fetchedData }}</h1>
		<button @click="getPin()">Get Pin</button>
		<h1>{{ this.pinData }}</h1>
	</div>
</template>

<script>
export default{
	data(){
		return{
			fetchedData: '',
			pinData:''
		};
	},
	methods: {
		async getData(){
			let res = await fetch('/fact');
			this.fetchedData = await res.json();
			console.log(this.fetchedData);
		},
		async getPin(){
			let res = await fetch('/pin/us/33184');
			this.pinData = await res.json();
			console.log(this.pinData);
		}
	}
};
</script>