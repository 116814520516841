<template>
	
	<el-drawer custom-class="profile-form" direction="ltr" size="40%" :model-value="openProfileForm" :with-header="false" @closed="afterFormClosed">
		<div class="headingContainer flex--row flex--sbw">
			<span class="heading">My Preferences</span>
			<span @click="openProfileForm = false" class="closeBtn">X</span>
		</div>

		<div class="menuContainer">
			<el-menu :default-active="activeIndex" mode="horizontal" @select="menuHandler">
			<el-menu-item index="1">Profile</el-menu-item>
			<el-menu-item index="2">Notifications</el-menu-item>
			<!-- <el-menu-item index="3">Privacy</el-menu-item>
			<el-menu-item index="4">Settings</el-menu-item> -->
			</el-menu>
		</div>

		<div class="menuContent">
			<template v-if="selectionVal == 1">
				<div class="profileContainer flex--row flex--ac">
					<div class="profileDetails flex--col">
						<div class="profilePic flex--row">
							<div class="chg-prf-pic">
								<!-- <av-icon v-if="this.currentAuthUser.profilePicURL === null" class="cp icon picIcon ex-large" @click="profilePicDialogue = true" name="account-circle-24"></av-icon> -->
								<template v-if="currentAuthUser.profilePicURL === null">
									<span :style="{'background-color': currentAuthUser.profilePicColor , 'color': checkColorluminance(currentAuthUser.profilePicColor) }" @click="profilePicDialogue = true" class = "cp txt24 image flex--row flex--acjc cp">{{ currentAuthUser.firstName.charAt(0).toUpperCase() }}</span>
								</template>
								<div v-else class="profile-pic-hover">
									<img :src="this.imgsrc()" class="image"/>
									<div class="overlay" @click="profilePicDialogue = true">Change/delete profile pic</div>
								</div>
								
							</div>
							<div class="flex--col">
								<span>{{ this.currentAuthUser.firstName }}<img class="editIcon" src="../icons/pen.png" @click="userNameDialogue = true"/></span>
								<span class="emailText">{{ this.currentAuthUser.email }}</span>
								<span class="changePwd" @click="passwordDialogue = true">Change Password</span>
							</div>
						</div>
						<div class="timeDetails">
							<div class="flex--row dateInput flex--ac">
								<span>Date format: </span>
								<el-dropdown  ref="dateFormatdropDown" @command="setDateFormat" trigger="click">
									<span class="dateInputselect">
										<span class="cp">{{ this.timeFormat }}</span>
										<img class="editIcon" src="../icons/pen.png" />
									</span>
									<template #dropdown>
									<el-dropdown-menu>
										<el-dropdown-item command="DD/MM/YYYY">DD/MM/YYYY hh:mm:ss a</el-dropdown-item>
										<el-dropdown-item command="MM/DD/YYYY">MM/DD/YYYY hh:mm:ss a</el-dropdown-item>
										<el-dropdown-item command="YYYY/MM/DD">YYYY/MM/DD hh:mm:ss a</el-dropdown-item>
										<!-- <el-dropdown-item value="yyyy/MM/dd HH:mm:ss a">yyyy/MM/dd HH:mm:ss</el-dropdown-item> -->
									</el-dropdown-menu>
									</template>
								</el-dropdown>
								
								
							</div>

							<div class="timeZoneInput flex--row flex--ac">
								<span>Timezone:</span>
								<el-select class="selectTimezone"  filterable @change="setTimezone" v-model="systemTz" placeholder="Select" size="small">
									<el-option
										v-for="time in timeZoneValue"
										:key="time"
										:label="`${time.zone} ${time.zoneNames}`"
										:value="`${time.zoneNames}`"
										/>
								</el-select>

								<!-- <av-zone></av-zone> -->
							</div>
							<!-- <div class="weekdayInput flex--row flex--ac">
								<span @click="clearFunction">Week starts on: </span>

								<select id="weekStart" name="weekStart">
									<option value="sun">Sunday</option>
									<option value="mon">Monday</option>
									<option value="tues">Tueday</option>
									<option value="wed">Wednesday</option>
									<option value="thu">Thursday</option>
									<option value="time4">Friday</option>
									<option value="time4">Saturday</option>
								</select>
							</div> -->
						</div>
					</div>
				</div>
			</template>

			<template v-if="selectionVal == 2">
				<table class="notifTableInput">
					<tr>
						<th>Notifications</th>
						<th>Email</th>
						<th>In-Product</th>
						<th>Desktop</th>
					</tr>
					<tr>
						<td>Task Assigned to me</td>
						<td><input type="checkbox"></td>
						<td><input type="checkbox"></td>
						<td><input type="checkbox"></td>
					</tr>
					<tr>
						<td>Created task status update</td>
						<td><input type="checkbox"></td>
						<td><input type="checkbox"></td>
						<td><input type="checkbox"></td>
					</tr>
					<tr>
						<td>Mentions in comments</td>
						<td><input type="checkbox"></td>
						<td><input type="checkbox"></td>
						<td><input type="checkbox"></td>
					</tr>
					<tr>
						<td>Overdue tasks</td>
						<td><input type="checkbox"></td>
						<td><input type="checkbox"></td>
						<td><input type="checkbox"></td>
					</tr>
				</table>
                <div class="btn-cont">
                    <el-button>Save changes</el-button>
                </div>
                
				
			</template>
			
			<!-- Profile pic dialog box-->
			<el-dialog v-model="profilePicDialogue" title="Upload a picture" width="50%">
				<div class="flex--col ">
					<el-upload ref="fileUpload" class="profilePicDialogue" action="#" :on-remove="handleRemove"
						accept="image/*" :auto-upload="false" :show-file-list="false" @input="crop">
						<template #trigger>
							<el-button class="browse-pic" type="primary">Browse pic</el-button>
						</template>
						<el-button class="upload-pic" @click="uploadProfPicServer">Upload chosen pic</el-button>
						<el-button v-if="this.currentAuthUser.profilePicURL !== null" type="danger" @click="deleteProfilePic">Delete exists profile pic</el-button>
					</el-upload>
				</div>
				<div class="crop-img-div">
					<img class="img" :src="destination">
				</div>
				
				
				<!-- <template #footer>
				<span class="dialog-footer">
				</span>
				</template> -->
			</el-dialog>

			<!-- Password dialog box -->
			<el-dialog v-model="passwordDialogue" title="Change Password" width="35%" >
				<!-- <div class="flex--col">
					<input type="password" class="pwdInput" v-model="origPwdInputTxt" placeholder="Enter existing Password" >
					<input type="password" class="pwdInput" v-model="newPwdInputTxt" placeholder="Enter new password">
					<input type="password" class="pwdInput" v-model="confNewPwdInputTxt" placeholder="Confirm new password">
					<template v-if="pwdMisMatch">
						<span>Password doesn't match</span>
					</template>
				</div> -->

				<el-form ref="updateUserPassword" :rules="updateUserPasswordRules" :model="updateUserPassword" >
					<el-form-item label="Existing password :" prop="currentPassword" >
						<el-input type="password" size="small" show-password v-model="updateUserPassword.currentPassword" placeholder="Enter existing password"/>
					</el-form-item>
					<el-form-item label="New password :" prop="newPassword" >
						<el-input type="password" size="small" show-password v-model="updateUserPassword.newPassword" placeholder="Enter new password"/>
					</el-form-item>
					<el-form-item label="Confirm password :" prop="confirmPassword" >
						<el-input type="password" size="small" show-password v-model="updateUserPassword.confirmPassword" placeholder="Enter confirm password"/>
					</el-form-item>
				</el-form>
				
				<template #footer>
				<span class="dialog-footer">
					<el-button @click="passwordDialogue = false">Cancel</el-button>
					<el-button type="primary" @click="updatePwd('updateUserPassword')">Confirm</el-button>
				</span>
				</template>
			</el-dialog>

			<!--User name dialog box-->
			<el-dialog v-model="userNameDialogue" title="Change User Name" width="30%" @close="updateUsername.userFirstName = ''">
				<el-form ref= "updateUsername" :rules="updateUserNameRules" :model="updateUsername" >
					<el-form-item label="Username :" prop="userFirstName" >
						<el-input type="text" size="small" v-model="updateUsername.userFirstName" placeholder="Enter first name"/>
					</el-form-item>
				</el-form>
				
				<template #footer>
				<span class="dialog-footer">
					<el-button @click="userNameDialogue = false">Cancel</el-button>
					<el-button type="primary" @click="updateUserName('updateUsername')">Confirm</el-button>
				</span>
				</template>
			</el-dialog>
		</div>
		
	</el-drawer>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const usersState = createNamespacedHelpers('users');
// import avIcon from '@/components/av-icon.vue';
import moment from 'moment-timezone';
import Cropper from 'cropperjs';
// import zone from '@/components/av-timezone.vue';

export default ({
	emits : ["onClose"],
	components : {
		// 'av-icon' : avIcon
		// 'av-zone' : zone
	},

	mounted () {
		this.moment = moment;
		this.setZoneNames();

	},

	created(){

		this.activeIndex = '1';
		this.selectionVal = this.activeIndex;
		// await this.$store.dispatch('users/getCurrentUser');
		this.dateFormat = `${this.currentAuthUser.dateFormat} ${"hh:mm:ss a"}`;
		this.momentTz = this.currentAuthUser.timeZone;
		this.imgsrc();

	},
	computed: {
		...usersState.mapState([
			'currentAuthUser'
		])
	},
	
	data () {
		let validatePass = (rule, value, callback) => {
				if (value !== this.updateUserPassword.newPassword) {
					callback(new Error('The confirm password is not the same as the new password.'));
				}
				else {
					callback();
				}
		};
		return {
			destination : "",
			systemTz: '',
			momentTz : '',
			updateUsername : {
				userFirstName : ''
			},
			updateUserNameRules : {
				userFirstName : [
					{
						required: true,
						message: 'Please input First Name',
						trigger: 'blur'
					},
					{
						min: 3,
						message: 'Length should be minimum 3 characters',
						trigger: 'blur'
					}
				]
			},
			updateUserPassword : {
				currentPassword : '',
				newPassword : '',
				confirmPassword : ''
			},
			updateUserPasswordRules : {
				currentPassword : [
					{
						required: true,
						message: 'Please input Existing password',
						trigger: 'blur'
					}
				],
				newPassword : [
					{
						required: true,
						message: 'Please input New Password',
						trigger: 'blur'
					},
					{
						min: 8,
						message: 'Length should be minimum 8 characters',
						trigger: 'change'
					},
					{
						trigger: 'change',
						validator (rule, value, callback) {
							if (!/[A-Z]{1}/.test(value)) {
							callback(new Error('At least one capital letter'));
							}
							else if (!/[a-z]{1}/.test(value)) {
							callback(new Error('At least one small letter'));
							}
							else if (!/[^A-Za-z0-9]/.test(value)) {
							callback(new Error('At least one special character'));
							}
							else if (!/\d/.test(value)) {
							callback(new Error('At least one number'));
							}
							else {
							callback();
							}
						}
					}

				],
				confirmPassword : [
					{
						required: true,
						message: 'Please input Confirm Password',
						trigger: 'blur'
					},
					{	validator: validatePass,
						trigger: 'change'}
				]
			},
			openProfileForm : true,
			selectionVal: '',
			activeIndex: '',
			passwordDialogue: false,
			pwdMisMatch: false,
			userNameDialogue: false,
			profilePicDialogue: false,
			userNameIsEmpty: false,
			updateCurrentUser : {},
			showdateDD : false,
			dateFormat : '',
			timeFormat : '',
			uploadPic : {},
			timeInterval : '',
			fileList : [],
			timeZoneValue : [],
			croppingImage : {},
			cropper : {},
			dataUrl : {},
			fileName : ''
		};
	},

	methods: {

		checkColorluminance(color){
            const r = parseInt(color.slice(1, 3), 16);
            const g = parseInt(color.slice(3, 5), 16);
            const b = parseInt(color.slice(5, 7), 16);
    
            const relativeLuminance = ((0.2126 * r) + (0.7152 * g) + (0.0722 * b)) / 255;

            if (relativeLuminance > 0.5) {
                return '#000000';
            }
                return '#FFFFFF';

        },

		resetProPic(){
			// console.log(Object.keys(this.cropper).length);
			if( Object.keys(this.cropper).length !== 0){
				this.$refs.fileUpload.clearFiles();
				this.cropper.destroy();
				document.querySelector('.img').src = '';
			}
		},

		crop(e){

			this.resetProPic();
			this.fileName = e.target.files[0].name;
			this.uploadPic = e.target.files[0];

				let reader = new FileReader();
				reader.onload = function() {
					document.querySelector('.img').src = reader.result;
				};
				reader.readAsDataURL(e.target.files[0]);
				setTimeout(() =>{
					this.cropImageFn();
				}, 100);
        },

		cropImageFn(){
					this.croppingImage = document.querySelector('.img');
					this.cropper = new Cropper(this.croppingImage, {
						zoomable: false,
						initialAspectRatio : 0,
                    cropend:() => {
                        let canvas = this.cropper.getCroppedCanvas();
						this.dataUrl = canvas.toDataURL("image/png");
						this.dataURItoBlob(this.dataUrl);
                    }
                });
		},

		dataURItoBlob(dataURI) {

			// convert base64/URLEncoded data component to raw binary data held in a string
			let byteString = atob(dataURI.split(',')[1]);

			// separate out the mime component
			let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

			// write the bytes of the string to an ArrayBuffer
			let ab = new ArrayBuffer(byteString.length);
			let ia = new Uint8Array(ab);
			for (let i = 0; i < byteString.length; i++) {
				ia[i] = byteString.charCodeAt(i);
			}
			let file = new Blob([ab], { type: mimeString });
			let resultFile = new File([file], this.fileName, {type:file.type});
			this.uploadPic = resultFile;

		},

		setZoneNames(){
			this.timeZoneValue = moment.tz.names().map(line => ({zoneNames:line, zone:"GMT" + moment().tz(line).format('Z')}));
			this.systemTz = `GMT${moment().tz(this.momentTz).format('Z')} ${this.momentTz}`;
			this.showLocaleTime(this.momentTz);
		},

		async setTimezone(value){
			clearInterval(this.timeInterval);
			this.showLocaleTime(value);
			// console.log(value.substring(10));
			this.currentAuthUser.timeZone = value;
			await this.$store.dispatch('users/updatecurrentUser', this.currentAuthUser);
            
        },

		async setDateFormat(command){
			this.dateFormat = `${command} ${"hh:mm:ss a"}`;
			this.currentAuthUser.dateFormat = command;
			await this.$store.dispatch('users/updatecurrentUser', this.currentAuthUser);
		},

		showLocaleTime(timezone){
			let time = this;
				this.timeInterval = setInterval(() => {
				time.timeFormat = time.moment().tz(timezone).format(time.dateFormat);
				}, 10);
		},

		async deleteProfilePic(){

			await this.$store.dispatch('users/deleteUserProfilePic');
			this.$store.dispatch('tasks/getAll');
			this.$store.dispatch('organizations/getCurrentOrg');
			this.profilePicDialogue = false;
			this.$refs.fileUpload.clearFiles(['ready']);

		},
		openDrawer(){
			this.openProfileForm = true;
		},
		afterFormClosed(){
			this.$emit('onClose');
		},
		menuHandler(selection){
			this.selectionVal = selection;
		},
		updatePwd(formName){
			this.$refs[formName].validate(async (valid) => {
				if (valid) {
					await this.$store.dispatch('users/updatecurrentUserPassword', this.updateUserPassword);
					this.$refs[formName].resetFields();
					this.passwordDialogue = false;

				} else {
					return false;
				}
			});

		},

		updateUserName(formName){
				this.$refs[formName].validate(async (valid) => {
				if (valid) {
					
					this.updateCurrentUser.firstName = this.updateUsername.userFirstName;
					this.updateCurrentUser.userId = this.currentAuthUser.userId;

					await this.$store.dispatch('users/updatecurrentUser', this.updateCurrentUser);
					this.$refs[formName].resetFields();
					this.userNameDialogue = false;

				} else {
					return false;
				}
			});
		},
		handlePreview(UploadFile){
			console.log(UploadFile);
		},

		handleRemove(file){
			console.log(file);
		},

		handleExceed(){
			// this.$refs.fileUpload.clearFiles();
		},

		// changeFile(UploadFile){
		// 	console.log(this.destination);
		// 	if(this.destination !== ''){
		// 		// this.$refs.fileUpload.clearFiles(['ready']);
		// 		this.cropper.clear();
		// 		document.querySelector('.img').src = '';
		// 	}
			
		// 	this.fileName = UploadFile.raw.name;
		// 	// this.uploadPic = UploadFile.raw;
		// },

		async uploadProfPicServer(){

			this.profilePicDialogue = false;
			await this.$store.dispatch('users/uploadProfilePic', this.uploadPic);
			this.$store.dispatch('tasks/getAll');
			this.$store.dispatch('organizations/getCurrentOrg');

			this.uploadPic = '';
			this.cropper.destroy();
			document.querySelector('.img').src = '';

			this.imgsrc();
			this.$refs.fileUpload.clearFiles(['ready']);
			// this.$store.dispatch('organizations/getCurrentOrg');
		},

		imgsrc(){
			let url = this.currentAuthUser.profilePicURL;
			if(url !== undefined){
				if(this.currentAuthUser.provider !== 'local' && this.currentAuthUser.profilePicName === null ){
					this.picUrl = `${url}`;
					return this.picUrl;
				}
				this.picUrl = `${window.location.origin}/profilePic/${url}`;
				return this.picUrl;
			}
		}
	}
});
</script>
<style>
.crop-img-div{
	margin-top: 15px;
}
.img{
	max-width: 50%;
	display: block;
}
.browse-pic{
	margin: 0 5px;
}
.upload-pic{
	margin: 0 5px;
}
.profilePicDialogue{
	display: flex;
    flex-flow: wrap;

}
.profile-pic-hover {
  position: relative;
}

.overlay {
	position: absolute;
	bottom: 3px;
	background: rgb(0, 0, 0);
	background: rgba(0, 0, 0, 0.5); /* Black see-through */
	color: #f1f1f1;
	transition: .5s ease;
	opacity:0;
	color: white;
	font-size: 11px;
	text-align: center;
	border-bottom-right-radius: 35px;
	border-bottom-left-radius: 35px;
	cursor: pointer;
}

.profile-pic-hover:hover .overlay {
	opacity: 1;
}
.profilePic{
	border-bottom: solid 1px var(--el-menu-border-color);
    padding: 15px 25px;
	margin-top: 1rem;

}
.el-drawer.ltr{
	height: 75%;
}
.profile-form{
	display: flex;
	flex-direction: column;
    overflow: auto;

}
.closeBtn{
	cursor: pointer;
}
.menuContainer .el-menu.el-menu--horizontal{
	border: none;
}
.menuContainer .el-menu--horizontal>.el-menu-item{
	height: 50px;
    line-height: 50px;
}
.headingContainer{
	padding: 1rem;
	border-bottom: solid 1px var(--el-menu-border-color)
}
.profileContainer{
	margin-left: 1rem;
}
.profileDetails{
	margin-left: 2rem;
}
.emailText{
	margin-top:1rem;
	color: var(--color-7);
}
.chg-prf-pic{
	margin-right: 3rem;
}
.profilePic > .chg-prf-pic .image{
	border-radius: 50%;
	width: 5.5rem;
	height: 5.5rem;
	color: #fff;
}
/* .chg-prf-pic > svg.av-icon{
	color : var(--color-8);
} */
.editIcon{
	width: 1rem;
	height: 1rem;
	margin-left: 1rem;
	/* opacity: 80%; */
	cursor: pointer;
}
.changePwd{
	color: var(--color-20);
	margin-top:1rem;
	font-size: 0.8rem;
	cursor: pointer;
}
.changePwd:hover{
	color:var(--color-1);
}
.pwdInput{
	margin-top:0.5rem;
	border: solid;
	border-width: 0.02rem;
	border-color: var(--color-23);
	width:18rem;
	height: 2rem;
	color: var(--color-15);
	padding-left: 0.5rem;
	font-size:0.8rem;
}
.userNameInput{
	margin-top:0.5rem;
	border: solid;
	border-width: 0.02rem;
	border-color: var(--color-23);
	width:18rem;
	height: 2rem;
	color: var(--color-11);
	padding-left: 0.5rem;
	font-size:0.8rem;
}
.timeDetails{
	padding: 15px 25px;
	margin-top: 2rem;
}
.dateInput{
	/* margin-top:5rem; */
}
.dateInputselect{
	margin-left:3rem;
}
.timeZoneInput{
	margin-top:1rem;
}
.timeZoneInput select{
	margin-left:4rem;
	border:solid;
	border-width: 0.05rem;
	border-color: var(--color-23);
}
.notifTableInput{
	margin:1.5rem 0;

}
.notifTableInput td{
	text-align: center;
	margin-top: 0.5rem;
	padding-left: 3rem;
	padding-top:1rem;
	color: var(--color-12);
}
.notifTableInput th{
	text-align: center;
	padding-left: 2rem;
}
.weekdayInput{
	margin-top:2rem;
}
.weekdayInput{
	margin-top:1rem;
}
.weekdayInput select{
	margin-left:1.8rem;
	border:solid;
	border-width: 0.05rem;
	border-color: var(--color-23);
}
.btn-cont{
    text-align: end;
    margin-right: 20px;
}
.selectTimezone{
    margin-left: 3rem;
}
</style>